import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { DescriptiorText, DetailText } from "../mass_import_styles";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import StyledButton from "../../../assets/buttons";
import styled from "@emotion/styled";

const DropzoneDiv = styled.div`
  border: 2px dashed #007bff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: border-color 0.3s ease-in-out;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, #e644e3, #d89b00);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -1; /* Place the gradient below the content */
  }

  ${({ isDragActive }) =>
    isDragActive &&
    `
    border-color: #d89b00;
    &::before {
      opacity: 1;
    }
  `}
`;

const CSVImporter = ({
  setData,
  headerOptions,
  requiredHeaders,
  exclusiveHeaders,
}) => {
  const [parsedData, setParsedData] = useState([]);
  const [userHeaders, setUserHeaders] = useState([]);

  const [includeFirstRow, setIncludeFirstRow] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
        alert("Please upload a valid CSV file.");
        return;
      }
      Papa.parse(file, {
        complete: (result) => {
          setParsedData(result.data);
          // Initialize userHeaders with null values, one for each column in the first row
          setUserHeaders(Array(result.data[0].length).fill(null));
        },
        header: false,
      });
    });
  }, []);

  const autoMapHeaders = useCallback(() => {
    if (parsedData.length > 0 && !includeFirstRow) {
      const firstRowHeaders = parsedData[0];
      const usedHeaders = new Set(); // Set to keep track of headers already used
      const newHeaders = firstRowHeaders.map((headerText) => {
        const normalizedHeaderText = headerText.toLowerCase().trim();

        // First, try to find a direct exact match that hasn't been used yet
        let match = headerOptions.find((option) => {
          const normalizedOption = option.toLowerCase().trim();
          return (
            normalizedOption === normalizedHeaderText &&
            !usedHeaders.has(normalizedOption)
          );
        });

        // If no exact match, then look for partial matches where one word matches, that hasn't been used yet
        if (!match) {
          match = headerOptions.find((option) => {
            const normalizedOption = option.toLowerCase();
            return (
              !usedHeaders.has(normalizedOption) &&
              (normalizedOption
                .split(" ")
                .some((part) => normalizedHeaderText.includes(part)) ||
                normalizedHeaderText
                  .split(" ")
                  .some((part) => normalizedOption.includes(part)))
            );
          });
        }

        // If a match is found, add it to the set of used headers
        if (match) {
          usedHeaders.add(match.toLowerCase().trim());
        }

        return match || ""; // Return the found match or an empty string to indicate no match
      });

      setUserHeaders(newHeaders);
    }
  }, [parsedData, includeFirstRow, headerOptions]);

  useEffect(() => {
    autoMapHeaders();
  }, [autoMapHeaders]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  /**
   * Handles changes to header selections in a table by enforcing exclusivity rules.
   *
   * @param {Object} e - The event object from the input change.
   * @param {number} index - The index of the header being changed.
   * @param {Array<Array<string>>} exclusivityGroups - An array of arrays where each inner array
   *        contains headers that are exclusive to headers in other inner arrays.
   *        For example, [["Amount"], ["Inflow", "Outflow"]] means "Amount" is exclusive with
   *        "Inflow" and "Outflow", but "Inflow" and "Outflow" can coexist.
   *
   * Usage: Call this function on onChange event of header selection inputs, passing
   *        the appropriate exclusivityGroups for the context.
   */
  const handleHeaderChange = ({ e, index, exclusivityGroups }) => {
    const newHeaders = [...userHeaders]; // Create a copy of the current header state
    console.log(e.target.value, index, exclusivityGroups);
    const selectedValue = e.target.value; // Get the new value from the event

    // Update the selected header with the new value
    newHeaders[index] = selectedValue;
    if (exclusivityGroups.length > 0) {
      // Process each group to enforce exclusivity
      exclusivityGroups.forEach((group) => {
        // Check if the selected value is part of the current group
        if (group.includes(selectedValue)) {
          // If it is, then loop through all other groups to enforce exclusivity
          exclusivityGroups.forEach((otherGroup) => {
            if (otherGroup !== group) {
              newHeaders.forEach((header, idx) => {
                // Clear any header that is part of an exclusive group and is not the current index
                if (otherGroup.includes(header) && idx !== index) {
                  newHeaders[idx] = ""; // Set conflicting headers to an empty string
                }
              });
            }
          });
        }
      });
    }
    // Update the state with the modified headers
    setUserHeaders(newHeaders);
  };

  const applyMapping = () => {
    console.log("requiredHeaders: ", requiredHeaders);
    //Check if the headers selected include all required headers
    const allRequiredHeadersMapped = requiredHeaders.some((headerOption) => {
      console.log("headerOption: ", headerOption);
      return headerOption.every((requiredHeader) =>
        userHeaders.includes(requiredHeader),
      );
    });
    if (!allRequiredHeadersMapped) {
      //Build a list of headers that are missing
      const findBestMatchingHeaders = (userHeaders, requiredHeadersOptions) => {
        let bestMatch = null;
        let maxOverlap = 0;

        requiredHeadersOptions.forEach((option) => {
          const overlapCount = option.filter((header) =>
            userHeaders.includes(header),
          ).length;

          // Only consider options where there's at least one header matching
          if (overlapCount > 0) {
            // Calculate proportion of matched headers to total headers in the option
            const overlapRatio = overlapCount / option.length;

            // Update best match if this option has a higher ratio of overlap
            if (overlapRatio > maxOverlap) {
              bestMatch = option;
              maxOverlap = overlapRatio;
            }
          }
        });

        return bestMatch;
      };

      const alertMissingHeaders = (userHeaders, requiredHeadersOptions) => {
        const bestMatchingHeaders = findBestMatchingHeaders(
          userHeaders,
          requiredHeadersOptions,
        );

        if (bestMatchingHeaders) {
          const missingHeaders = bestMatchingHeaders.filter(
            (requiredHeader) => !userHeaders.includes(requiredHeader),
          );

          if (missingHeaders.length > 0) {
            alert(
              "Please map the following headers: " + missingHeaders.join(", "),
            );
            return;
          } else {
            alert("All required headers are mapped correctly.");
            return;
          }
        } else {
          alert(
            "No valid header configuration found. Please review the required headers.",
          );
          return;
        }
      };
      alertMissingHeaders(userHeaders, requiredHeaders);
    } else {
      const dataToMap = includeFirstRow
        ? parsedData.slice(0)
        : parsedData.slice(1);
      const transformedData = dataToMap.map((row) => {
        const transformedRow = {};
        row.forEach((cell, index) => {
          const header = userHeaders[index];
          if (header) {
            // This condition effectively ignores columns with an empty string as the header
            //Need to change headers that have spaces to underscores
            transformedRow[header.replace(/\s/g, "_")] = cell;
          }
        });
        return transformedRow;
      });

      console.log("csv data transformed in CSVImporter: ", transformedData);
      setData(transformedData);
    }
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#00b4d8",
      },
    },
  });

  return (
    <div>
      {!parsedData.length > 0 ? (
        <DropzoneDiv {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <DescriptiorText>
              Drop the file to begin the import process...
            </DescriptiorText>
          ) : (
            <DescriptiorText style={{ textAlign: "center" }}>
              Drag and drop a CSV files here, or click here to select files
            </DescriptiorText>
          )}
        </DropzoneDiv>
      ) : (
        <div style={{ width: "max-content", maxWidth: "90vw" }}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Checkbox
                  checked={includeFirstRow}
                  onChange={() => setIncludeFirstRow(!includeFirstRow)}
                  style={{ color: "#00b4d8" }}
                />
                <DescriptiorText
                  style={{ cursor: "pointer", fontSize: "0.9rem" }}
                  onClick={() => setIncludeFirstRow(!includeFirstRow)}>
                  Include first row as data
                </DescriptiorText>
              </div>
              <h3 style={{ color: "white" }}>Please Map Your CSV Headers</h3>
              <StyledButton bright fontSize={"1.1rem"} onClick={applyMapping}>
                Apply Mapping
              </StyledButton>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                {parsedData[0].map((_, columnIndex) => (
                  <th key={columnIndex}>
                    {!includeFirstRow && (
                      <div>
                        <DescriptiorText
                          style={{ fontSize: "0.9rem", color: "#bdbdbdF" }}>
                          {parsedData[0][columnIndex]}
                        </DescriptiorText>
                      </div>
                    )}
                    <ThemeProvider theme={darkTheme}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Header
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={userHeaders[columnIndex] || ""}
                          onChange={(e) =>
                            handleHeaderChange({
                              e,
                              index: columnIndex,
                              exclusivityGroups: exclusiveHeaders,
                            })
                          }
                          label="Header">
                          <MenuItem value="">
                            <em>Ignore this column</em>
                          </MenuItem>
                          {headerOptions.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ThemeProvider>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {parsedData
                .slice(includeFirstRow ? 0 : 1, includeFirstRow ? 16 : 17)
                .map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>
                        <DetailText>{cell}</DetailText>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CSVImporter;
