import React, { useContext, useState, useEffect } from "react";
import ReconciliationStatus from "./reconciliation_status";
import { DoneOutline } from "@mui/icons-material";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import UserContext from "../../../assets/user_context";
import { useAuth } from "../../../services/use-auth";
import ReconciliationStartingOptions from "./reconcilliation_starting_options";
import DayAlert, { TitleText } from "./alerts";
import RegisterTransactions from "../register_transactions/register_transactions";
import { returnDateForDisplay } from "./rec_helper_tools";
import {
  LineText,
  StyledTitleText,
} from "../register_transactions/register_transactions_styles";
import { CompleteRecButton, CompleteText } from "./reconciliation_styles";
import dayjs from "dayjs";
import StyledButton from "../../../assets/buttons";
import { primary_dark_color } from "../../../constants/color_constants";
import { CircularProgress } from "@mui/material";

const ReconciliationTool = ({
  account,
  mostRecentRec,
  setInARec,
  selectedAccount,
  registerAccounts,
  recInProgress,
  setRecInProgress,
  setProcessingRec,
  processingRec,
}) => {
  const {
    org,
    transactions,
    user,
    userData,
    experimental,
    setExperimentalReconcilliations,
    setDateRangeForTx,
  } = useContext(UserContext);
  const authHook = useAuth();
  const db = authHook.db;
  const [filteredTx, setFilteredTx] = useState([]);
  const [clearedTx, setClearedTx] = useState([]);
  const [initialOptionsModal, setInitialOptionsModal] = useState(true);
  const [closingBalanceData, setClosingBalanceData] = useState({
    date: null,
    amount: "",
  });
  const [openingBalance, setOpeningBalance] = useState({
    date: null,
    amount: null,
  });
  const [recBalanced, setRecBalanced] = useState(false);
  const [dateAlertModalOpen, setDateAlertModalOpen] = useState(false);
  const [txNotToShow, setTxNotToShow] = useState([]);
  const [alreadyBegun, setAlreadyBegun] = useState(false);

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      // Get the viewport height
      const vh = window.innerHeight;

      // Subtract the height of other elements, e.g., header, footer

      const reconcilliationStatus =
        document.querySelector("#reconcilliation-status")?.offsetHeight || 0;
      const extra = experimental ? 230 : 190;
      const height = vh - (reconcilliationStatus + extra);

      console.log(
        `height: ${height}, vh: ${vh}, reconcilliationStatus: ${reconcilliationStatus}`,
      );
      const heightString = height.toString().concat("px");
      console.log(heightString);

      // Set the height for the table wrapper
      setTableWrapperHeight(heightString);
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === "reconciliation-status") {
          calculateHeight();
        }
      }
    });

    // Observe the target element
    const statusComponentObserver = document.querySelector(
      "#reconciliation-status",
    );
    if (statusComponentObserver) {
      resizeObserver.observe(statusComponentObserver);
    }

    // Calculate on mount and on window resize
    calculateHeight();
    window.addEventListener("resize", calculateHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, [experimental]);

  //log clearedTx when it changes
  useEffect(() => {
    console.log("ClearedTx", clearedTx);
  }, [clearedTx]);

  //useEffect to get all transaction lines from selected account and filter by date including and before the rec date you're working on
  //For all transactions that are not cleared BEFORE the rec being worked on, add them to the filteredTx state
  useEffect(() => {
    console.log(
      "Checking if we should filter transactions",
      account?.id,
      transactions.length > 0,
      dayjs.isDayjs(openingBalance?.date),
      dayjs.isDayjs(closingBalanceData?.date),
    );
    if (
      account?.id &&
      transactions.length > 0 &&
      openingBalance?.date &&
      closingBalanceData?.date
    ) {
      const newTxWithLines = transactions
        .filter((tx) => tx.lines.some((line) => line.account === account.id))
        .filter((tx) => {
          const isBeforeClosingDate =
            tx.date && closingBalanceData.date
              ? dayjs(tx.date).isSameOrBefore(
                  dayjs(closingBalanceData.date),
                  "day",
                )
              : false;
          const isAfterOpeningDate =
            tx.date && openingBalance.date
              ? dayjs(tx.date).isAfter(dayjs(openingBalance.date), "day")
              : false;
          const lineForAccountCleared = tx.lines
            .filter((line) => line.account === account.id)
            .some((line) => line.cleared);
          return (
            (isBeforeClosingDate && isAfterOpeningDate) ||
            (isBeforeClosingDate && !lineForAccountCleared)
          );
        })
        .map((tx) => ({
          ...tx,
          lines: tx.lines.filter((line) => line.account === account.id),
        }));
      const txListNoShow = transactions.filter((tx) => {
        const isAfterOpeningDate = tx.date?.$d > openingBalance.date?.$d;
        const lineForAccountCleared = tx.lines
          .filter((line) => line.account === account.id)
          .some((line) => line.cleared);
        return !isAfterOpeningDate && lineForAccountCleared;
      });
      setTxNotToShow(txListNoShow);
      console.log("Filtered Transactions to allow clearing:", newTxWithLines);
      setFilteredTx(newTxWithLines);
    }
  }, [transactions, account, openingBalance, closingBalanceData]);

  //useEffect to set opening balance and its date if there are no previous reconcilliations
  useEffect(() => {
    if (!mostRecentRec && transactions.length > 0 && account?.id) {
      const earliestTxDate = transactions
        .filter((tx) => tx.lines.some((line) => line.account === account.id))
        .reduce((prev, curr) => {
          return prev.date < curr.date ? prev : curr;
        });
      console.log("No recs in parent", earliestTxDate);
      setOpeningBalance({
        date: earliestTxDate.date.subtract(1, "day"),
        amount: 0,
      });
    } else if (mostRecentRec && transactions.length > 0) {
      console.log("There is a recent rec: ", mostRecentRec);
      setOpeningBalance({
        date: mostRecentRec?.closingDate.toDate(),
        amount: mostRecentRec?.closingBalance,
      });
    } else {
      console.log("No transactions to reconcile");
    }
  }, [account?.id, mostRecentRec, transactions]);

  // // useEffect to set all imported transactions from plaid without changed amounts to cleared
  // useEffect(() => {
  //   if (filteredTx && openingBalance.date) {
  //     const plaidClearedTx = [];
  //     filteredTx.forEach((tx) => {
  //       if (
  //         tx.plaidTx &&
  //         tx.plaidTx.amount ===
  //           Math.abs(
  //             tx.lines.reduce((prev, curr) => {
  //               return prev + parseFloat(curr.amount);
  //             }, 0),
  //           )
  //       ) {
  //         plaidClearedTx.push(tx.id);
  //       }
  //     });
  //     setClearedTx(plaidClearedTx);
  //   }
  // }, [filteredTx, openingBalance]);

  const clickedToToggleCleared = ({ id, action }) => {
    console.log(`Clicked ${action} on ${id}`);
    if (!alreadyBegun) {
      setAlreadyBegun(true);
    }
    if (action === "clear") {
      //Check if this tx is in our filtered tx, otherwise it could be outside our time range
      if (filteredTx.some((tx) => tx.id === id)) {
        setClearedTx([...clearedTx, id]);
      } else {
        setDateAlertModalOpen(true);
      }
    } else if (action === "unclear") {
      setClearedTx(clearedTx.filter((txId) => txId !== id));
    }
  };

  // useEffect to check if there is a recInProgress and set the cleared tx if they have not changed as well as the ending balance and date
  useEffect(() => {
    if (recInProgress && recInProgress.account === account.id) {
      console.log("recInProgress SETTING", recInProgress);
      setClearedTx(recInProgress.clearedTxIds);
      setClosingBalanceData({
        date: dayjs(recInProgress.closingDate.toDate()),
        amount: recInProgress.closingBalance,
      });
      //Get a date  called firstDate 6 months before
      let closingBalanceDate = recInProgress.closingDate.toDate();
      let firstDate = closingBalanceDate;
      firstDate.setMonth(firstDate.getMonth() - 6);
      let lastDate = closingBalanceDate;
      lastDate.setDate(lastDate.getDate() + 7);
      setDateRangeForTx({
        firstDate: dayjs(firstDate),
        lastDate: dayjs(recInProgress.closingDate.toDate()),
      });
      setInitialOptionsModal(false);
    } else {
      console.log("No rec in progress");
    }
  }, [account.id, recInProgress]);

  const onCompleteRec = async () => {
    if (recBalanced) {
      //Grab all current tx states for cleared tx
      const txCurrentState = transactions
        .filter((tx) => clearedTx.includes(tx.id))
        .map((tx) => {
          return { ...tx, date: tx.date.$d };
        });
      console.log("txCurrentState", txCurrentState);
      console.log("openingDate", openingBalance.date);
      if (!experimental) {
        setProcessingRec(true);
        const currentStateOfRec = {
          account: account.id,
          openingBalance: openingBalance.amount,
          closingBalance: closingBalanceData.amount,
          closingDate: closingBalanceData.date.$d,
          openingDate: openingBalance.date.$d || openingBalance.date,
          clearedTxIds: clearedTx,
          unClearedTxIds: filteredTx
            .filter((tx) => !clearedTx.includes(tx.id))
            .map((tx) => tx.id),
          txState: txCurrentState,
          user: user.uid,
          username: userData.username,
          inProgress: false,
        };
        if (recInProgress?.id) {
          console.log("saved rec, replacing", recInProgress.id);
          await setDoc(
            doc(db, "orgs", org, "reconciliations", recInProgress.id),
            currentStateOfRec,
          );
          setRecInProgress({});
        } else {
          await addDoc(
            collection(db, "orgs", org, "reconciliations"),
            currentStateOfRec,
          );
        }
        setInARec(false);
      } else {
        setExperimentalReconcilliations((prevRecs) => [
          ...(Array.isArray(prevRecs) ? prevRecs : []),
          {
            account: account.id,
            openingBalance: openingBalance.amount,
            closingBalance: closingBalanceData.amount,
            closingDate: closingBalanceData.date.$d,
            openingDate: openingBalance.date.$d || openingBalance.date,
            clearedTxIds: clearedTx,
            unClearedTxIds: filteredTx
              .filter((tx) => !clearedTx.includes(tx.id))
              .map((tx) => tx.id),
            txState: txCurrentState,
            user: user.uid,
            username: userData.username,
          },
        ]);
        setInARec(false);
      }
    }
  };

  const onSaveRec = async () => {
    const txCurrentState = transactions
      .filter((tx) => clearedTx.includes(tx.id))
      .map((tx) => {
        return { ...tx, date: tx.date.$d };
      });
    console.log("txCurrentState", txCurrentState);
    console.log("openingDate", openingBalance.date);
    const currentStateOfRec = {
      account: account.id,
      openingBalance: openingBalance.amount,
      closingBalance: closingBalanceData.amount,
      closingDate: closingBalanceData.date.$d,
      openingDate: openingBalance.date.$d || openingBalance.date,
      clearedTxIds: clearedTx,
      txState: txCurrentState,
      user: user.uid,
      username: userData.username,
      inProgress: true,
    };
    if (recInProgress?.id) {
      console.log("recInProgress SAVING", recInProgress.id, currentStateOfRec);
      await setDoc(
        doc(db, "orgs", org, "reconciliations", recInProgress.id),
        currentStateOfRec,
      );
    } else {
      console.log("No recInProgress SAVING", currentStateOfRec);
      await addDoc(
        collection(db, "orgs", org, "reconciliations"),
        currentStateOfRec,
      );
    }
  };

  useEffect(() => {
    // Add beforeunload event listener to handle browser close/tab close events
    const handleBeforeUnload = (event) => {
      onSaveRec();
      event.returnValue = ""; // Prompt user for confirmation to leave
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Dependencies array is empty, so this runs once on mount

  const setAllCleared = () => {
    setClearedTx(filteredTx.map((tx) => tx.id));
  };

  return (
    <React.Fragment>
      <DayAlert
        modalOpen={dateAlertModalOpen}
        setModalOpen={setDateAlertModalOpen}
        closingDate={closingBalanceData?.date?.format("MM/DD/YYYY")}
      />
      {selectedAccount && (
        <StyledTitleText style={{ marginTop: "15px" }}>{`Reconcile ${
          registerAccounts.find((account) => {
            return account.id === selectedAccount;
          }).accountName
        } Closing ${
          closingBalanceData.date !== null &&
          closingBalanceData.date !== undefined
            ? returnDateForDisplay(closingBalanceData.date)
            : ""
        }`}</StyledTitleText>
      )}

      <ReconciliationStartingOptions
        initialOptionsModal={initialOptionsModal}
        setInitialOptionsModal={setInitialOptionsModal}
        closingBalanceData={closingBalanceData}
        setClosingBalanceData={setClosingBalanceData}
        setInARec={setInARec}
        alreadyBegun={alreadyBegun}
        lastRecDate={mostRecentRec?.closingDate || false}
      />
      <div
        id="reconcilliation-status"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "space-around",
          justifyContent: "center",
        }}>
        <ReconciliationStatus
          // id="reconcilliation-status"
          clearedTx={clearedTx}
          account={account}
          closingBalance={closingBalanceData.amount}
          openingBalance={openingBalance}
          setRecBalanced={setRecBalanced}
          filteredTx={filteredTx}
          setInitialOptionsModal={setInitialOptionsModal}
        />
        <div style={{ height: "1rem" }} />
        <div
          style={{
            marginRight: "2rem",
            borderRadius: "3rem",
            backgroundColor: "#f1f0f0",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <TitleText style={{ color: primary_dark_color }}>Controls</TitleText>
          <StyledButton primary fontSize="1rem" onClick={() => onSaveRec()}>
            Save Progress
          </StyledButton>
          <div style={{ height: "1rem" }} />
          <StyledButton primary fontSize="1rem" onClick={() => setAllCleared()}>
            Clear All
          </StyledButton>
          <div style={{ height: "1rem" }} />
          <StyledButton
            primary
            fontSize="1rem"
            onClick={() => setClearedTx([])}>
            Un-Clear All
          </StyledButton>
        </div>
      </div>
      {account?.id && (
        <RegisterTransactions
          height={tableWrapperHeight}
          filterAccount={account.id}
          clearedTx={clearedTx}
          clickedClear={clickedToToggleCleared}
          removeTransaction={txNotToShow}
          setAllClearedFunction={setAllCleared}
        />
      )}
      {recBalanced && clearedTx?.length > 0 && (
        <CompleteRecButton
          disabled={!recBalanced || processingRec}
          onClick={onCompleteRec}>
          {processingRec ? (
            <React.Fragment>
              <CompleteText>Checking...</CompleteText>
              <CircularProgress style={{ color: primary_dark_color }} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <CompleteText>Complete Reconciliation</CompleteText>
              <DoneOutline fontSize="large" />
            </React.Fragment>
          )}
        </CompleteRecButton>
      )}
    </React.Fragment>
  );
};

export default ReconciliationTool;
